@use 'sass:map';

$laps-gray-text: #696969;
$laps-dark-text: #333333;
$laps-light-text: #ffffff;
$laps-success: #00ae48;
$laps-contrast: (
  50: $laps-dark-text,
  100: $laps-dark-text,
  200: $laps-dark-text,
  300: $laps-dark-text,
  400: $laps-dark-text,
  500: $laps-dark-text,
  600: $laps-dark-text,
  700: $laps-dark-text,
  800: $laps-light-text,
  900: $laps-light-text,
);

$laps-primary-palette: (
  50: #e1f5f9,
  100: #b4e5f1,
  200: #85d3e8,
  300: #5ac2e0,
  400: #3cb5dc,
  500: #22a9d8,
  600: #199bcb,
  700: #0c88b8,
  800: #067799,
  900: #005984,
  contrast: $laps-contrast,
);

$laps-accent-palette: (
  50: #e1f7fb,
  100: #b4ebf4,
  200: #83deed,
  300: #54d0e4,
  400: #36c6dd,
  500: #005984,
  600: #22acc4,
  700: #1e97aa,
  800: #1a8391,
  900: #136066,
  contrast:
    map.merge(
      $laps-contrast,
      (
        500: $laps-light-text,
        600: $laps-light-text,
        700: $laps-light-text,
      )
    ),
);

$laps-warn-palette: (
  50: #ffebee,
  100: #fce7e7,
  200: #f19b9b,
  300: #e77474,
  400: #f25451,
  500: #f74437,
  600: #e83a36,
  700: #d63030,
  800: #c62828,
  900: #ba1d1d,
  contrast:
    map.merge(
      $laps-contrast,
      (
        700: $laps-light-text,
      )
    ),
);
