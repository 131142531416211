@use 'sass:map';
@use '@angular/material' as mat;

@import 'ag-grid-community/dist/styles/ag-grid.css';
@import 'ag-grid-community/dist/styles/ag-theme-material.css';
@import './variables.scss';

@font-face {
  font-family: 'News Gothic';
  src: url('assets/fonts/news-gothic.otf') format('opentype');
  font-weight: normal;
}
$laps-typography: mat.define-typography-config(
  $font-family: 'News Gothic',
);
@include mat.core($laps-typography);

$laps-primary: mat.define-palette($laps-primary-palette, 900, 100, 900);
$laps-accent: mat.define-palette($laps-accent-palette);
$laps-warn: mat.define-palette($laps-warn-palette, 700, 100, 900);
$laps-theme: mat.define-light-theme(
  (
    color: (
      primary: $laps-primary,
      accent: $laps-accent,
      warn: $laps-warn,
    ),
  )
);
@include mat.all-component-themes($laps-theme);
// TODO: only apply theme to necessary components
// @include mat.core-theme($laps-theme);
// @include mat.button-theme($laps-theme);

// OVERRIDING MATERIAL COMPONENT STYLE
.mat-form-field-appearance-legacy:not(.mat-form-field-disabled):not(.mat-form-field-invalid)
  .mat-form-field-label {
  color: map.get($laps-primary, 900);
}

input.mat-input-element,
textarea.mat-input-element {
  color: $laps-gray-text;
}

.mat-stroked-button:not(.mat-button-disabled),
.mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: map.get($laps-primary, 900);
  border-color: map.get($laps-primary, 900);
}

.mat-stroked-button.mat-button-disabled.mat-button-disabled {
  @apply opacity-75;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: map.get($laps-primary, 600);
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
  width: 100%;
}

.mat-error,
.mat-hint {
  @apply text-xs;
}

.mat-select-value {
  @apply text-gray-500;
}

.toast {
  &-success {
    background: $laps-success;
    color: $laps-light-text;
  }

  &-error {
    background: map.get($laps-warn, 100);
    color: map.get($laps-warn, 800);
  }

  &-top {
    position: absolute;
    top: 16px;
    margin: 0 !important;
  }

  &-centered {
    left: 50%;
    transform: translateX(-50%) !important;
  }
}

.alert {
  @apply max-w-md p-3 mb-4 rounded;
  &-error {
    background: map.get($laps-warn, 100);
    color: map.get($laps-warn, 800);
  }

  &-info {
    background: map.get($laps-primary, 800);
    color: $laps-light-text;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: 'News Gothic', Roboto, sans-serif;
}

.permits-font {
  @apply lg:text-lg sm:text-sm lg:leading-7 sm:leading-5 tracking-0.25px;
}

.readonly-field {
  pointer-events: none;

  .mat-form-field-underline {
    display: none;
  }

  .mat-select-arrow {
    border: none;
  }
}

.ag-theme-material {
  font-family: 'News Gothic', Roboto, sans-serif !important;

  .ag-header-row {
    border: 1px solid #e2e2e2 !important;
    border-bottom: 0px !important;
  }

  .ag-row-odd {
    background-color: #c6cbd4 !important;
  }

  .ag-icon-menu::before {
    content: '\f12a';
  }

  .ag-body-horizontal-scroll-viewport {
    overflow-x: auto;
  }
}

.mat-form-field-appearance-legacy .mat-form-field-suffix .mat-icon-button {
  @apply text-2xl w-6 h-6 #{!important};
}
